import * as reduxModule from "redux";
import { persistStore } from "redux-persist";
// import { compose, applyMiddleware, createStore, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import * as profileReducer from "./profile/reducer";

function createReducers(asyncReducer) {
  return reduxModule.combineReducers({
    ...profileReducer,
  });
}

reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : reduxModule.compose;

const middlewares = [thunkMiddleware];

const middlewareEnhancer = reduxModule.applyMiddleware(...middlewares);

const enhancers = composeEnhancers(middlewareEnhancer);

const store = reduxModule.createStore(createReducers(), enhancers);

store.asyncReducers = {};

export const persistor = persistStore(store);

export function injectReducer(key, reducer) {
  if (store.asyncReducers[key]) {
    return;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducers(store.asyncReducers));
  return store;
}

export default store;
