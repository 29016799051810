import React from "react";
import Loadable from "react-loadable";
import Loading from "./views/Components/LazyLoad";

const Dashboard = React.lazy(() => import("./views/Dashboard"));
// const Checkin = React.lazy(() => import('./views/Checkin/Checkin'));
// const Checkout = React.lazy(() => import('./views/PinjamBarang/PinjamBarang'));
// const StockOpname = React.lazy(() => import('./views/StockOpname/StockOpname'));
// const Inventory = React.lazy(() => import('./views/Inventory'));
const Temp = () => {
  return <div>Temporary</div>;
};
const User = Loadable({
  loader: () => import("./views/Users/index"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const UserAdmin = Loadable({
  loader: () => import("./views/Users/index-past"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.UserAdmin;
    return <Component {...props} />;
  },
});
const UserStudent = Loadable({
  loader: () => import("./views/Users/index-past"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.UserStudent;
    return <Component {...props} />;
  },
});
const UserTeacher = Loadable({
  loader: () => import("./views/Users/index-past"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.UserTeacher;
    return <Component {...props} />;
  },
});
const AddUser = Loadable({
  loader: () => import("./views/Users/AddEditUser/AddUser"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditUser = Loadable({
  loader: () => import("./views/Users/AddEditUser/Edituser"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const Merchants = Loadable({
  loader: () => import("./views/Users/index-past"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.Merchants;
    return <Component {...props} />;
  },
});
const Countries = Loadable({
  loader: () => import("./views/Countries"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const AddCountries = Loadable({
  loader: () => import("./views/Countries/AddCountries"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditCountries = Loadable({
  loader: () => import("./views/Countries/EditCountries"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});

/**
 * groups import
 */
const Groups = Loadable({
  loader: () => import("./views/Groups"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const AddGroups = Loadable({
  loader: () => import("./views/Groups/AddGroups"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditGroups = Loadable({
  loader: () => import("./views/Groups/EditGroups"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const GroupsMembers = Loadable({
  loader: () => import("./views/Groups/Members"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
/**
 * end groups import
 */

/**
 * schools import
 */
const Schools = Loadable({
  loader: () => import("./views/Schools"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const AddSchools = Loadable({
  loader: () => import("./views/Schools/AddSchools"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditSchools = Loadable({
  loader: () => import("./views/Schools/EditSchools"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
/**
 * end schools
 */

/**
 * policy import
 */
const Policies = Loadable({
  loader: () => import("./views/Policies"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const AddPolicy = Loadable({
  loader: () => import("./views/Policies/AddPolicies"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditPolicy = Loadable({
  loader: () => import("./views/Policies/EditPolicies"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
/**
 * end policy
 */

/**
 * roles import
 */
const Roles = Loadable({
  loader: () => import("./views/Roles"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const AddRoles = Loadable({
  loader: () => import("./views/Roles/AddRoles"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
const EditRoles = Loadable({
  loader: () => import("./views/Roles/EditRoles"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
/**
 * end roles
 */

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/home/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    routeName: "dashboard",
  },
  {
    path: "/home/schools",
    exact: true,
    name: "Schools",
    component: Schools,
    routeName: "schools",
  },
  {
    path: "/home/schools/addSchools",
    exact: true,
    name: "Add Schools",
    component: AddSchools,
    routeName: "schools/create",
  },
  {
    path: "/home/schools/editSchools/:id",
    exact: true,
    name: "Edit Schools",
    component: EditSchools,
    routeName: "schools/edit",
  },
  {
    path: "/home/policies",
    exact: true,
    name: "Policies",
    component: Policies,
    routeName: "policies",
  },
  {
    path: "/home/policies/AddPolicy",
    exact: true,
    name: "Add Policy",
    component: AddPolicy,
    routeName: "policies/create",
  },
  {
    path: "/home/policies/EditPolicy/:id",
    exact: true,
    name: "Edit Policy",
    component: EditPolicy,
    routeName: "policies/edit",
  },
  {
    path: "/home/roles",
    exact: true,
    name: "Roles",
    component: Roles,
    routeName: "roles",
  },
  {
    path: "/home/roles/addRole",
    exact: true,
    name: "Add Roles",
    component: AddRoles,
    routeName: "roles/create",
  },
  {
    path: "/home/roles/editRole/:id",
    exact: true,
    name: "Edit Roles",
    component: EditRoles,
    routeName: "roles/edit",
  },
  // { path: '/home/user/merchants', exact: true, name: 'Merchants', component: Merchants },
  // { path: '/home/user/merchants/addUser/:type', exact: true, name: 'Add Merchants', component: AddUser },
  // { path: '/home/user/merchants/editUser/:type/:id', exact: true, name: 'Edit Merchants', component: EditUser },
  {
    path: "/home/user",
    exact: true,
    name: "User",
    component: User,
    routeName: "user",
  },
  {
    path: "/home/user/add-user",
    exact: true,
    name: "Add User",
    component: AddUser,
    routeName: "user/create",
  },
  {
    path: "/home/user/editUser/:id",
    exact: true,
    name: "Edit User",
    component: EditUser,
    routeName: "user/edit",
  },
  {
    path: "/home/user/student",
    exact: true,
    name: "User Student",
    component: UserStudent,
  },
  {
    path: "/home/user/student/addUser/:type",
    exact: true,
    name: "Add Student",
    component: AddUser,
  },
  {
    path: "/home/user/student/editUser/:type/:id",
    exact: true,
    name: "Edit Student",
    component: EditUser,
  },
  {
    path: "/home/user/teacher",
    exact: true,
    name: "User Teacher",
    component: UserTeacher,
  },
  {
    path: "/home/user/teacher/addUser/:type",
    exact: true,
    name: "Add Teacher",
    component: AddUser,
  },
  {
    path: "/home/user/teacher/editUser/:type/:id",
    exact: true,
    name: "Edit Teacher",
    component: EditUser,
  },
  {
    path: "/home/user/admin",
    exact: true,
    name: "User Admin",
    component: UserAdmin,
  },
  {
    path: "/home/user/admin/addUser/:type",
    exact: true,
    name: "Add Admin",
    component: AddUser,
  },
  {
    path: "/home/user/admin/editUser/:type/:id",
    exact: true,
    name: "Edit Admin",
    component: EditUser,
  },
];

export default routes;
