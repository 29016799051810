import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AuthProvider, RouteAuthenticated, RouteHome } from "./libraries";
import { RouteContext } from "./libraries/route-context/routeContext";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "modules";


const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Authentication/Login"));

class App extends Component {
  render() {
    const auth = localStorage.getItem("directory:auth");

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouteContext.Consumer>
            {({ getRoute }) => (
              <HashRouter>
                <AuthProvider value={auth ? { auth: JSON.parse(auth) } : {}}>
                  <ToastContainer containerId="mainToast" />
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      <RouteAuthenticated
                        path="/home"
                        name="Home"
                        render={(props) => (
                          <DefaultLayout
                            getRoute={getRoute}
                            {...props}
                            cookies={this.props.cookies}
                          />
                        )}
                      />
                      <RouteHome
                        path="/"
                        exact
                        name="root"
                        component={(props) => (
                          <Login cookies={this.props.cookies} />
                        )}
                      />
                      <Route exact name="404" render={(props) => <div>404</div>} />
                    </Switch>
                  </React.Suspense>
                </AuthProvider>
              </HashRouter>
            )}
          </RouteContext.Consumer>
        </PersistGate>
      </Provider>
    );
  }
}

export default withCookies(App);
