import * as types from "./types";

export function setProfile(profile) {
  return dispatch =>
    dispatch({
      type: types.SET_PROFILE,
      data: profile
    });
}

export function unsetProfile() {
  return dispatch =>
    dispatch({
      type: types.UNSET_PROFILE,
      data: null
    })
}
