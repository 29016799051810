import * as types from "./types";
import { createReducer } from "@kempeb";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "vyneapple:profile",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["policies", "route_access", "domains"]
};

const initialState = {};

const reducer = createReducer(initialState, {
  [types.SET_PROFILE](state, payload) {
    return {
      ...state,
      ...payload.data,
    }
  },
  [types.UNSET_PROFILE](state, payload) {
    return {}
  },
});

export const Profile = persistReducer(persistConfig, reducer)
