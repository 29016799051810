import React from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthConsumer } from "./Auth";
import withContext from "./withContext";

const RouteAuthenticated = ({component: Component, render, auth, ...res}) => {
  // const isLoggedIn = false
  const authData = localStorage.getItem('directory:auth')
  if (!authData) {
    return <Redirect from="/:any" to="/"/>
  }
  return <Route {...res} render={render ? render : props => <Component {...props}/>}/>
}

const RouteHome = ({component: Component, render, auth, ...res}) => {
  // const isLoggedIn = false
  const authData = localStorage.getItem('directory:auth')
  if (authData) {
    return <Redirect from="/:any" to="/home/dashboard"/>
  }
  return <Route {...res} render={render ? render : props => <Component {...props}/>}/>
}

export default withContext(RouteAuthenticated, AuthConsumer)
export {RouteHome}
