export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/home',
    //   icon: 'icon-speedometer',
    // },
    {
      name: "Dashboard",
      url: "/home/dashboard",
      icon: "icon-home",
      routeName: "dashboard",
    },
    {
      name: "Organization",
      url: "/home/schools",
      icon: "icon-book-open",
      routeName: "schools",
    },
    {
      name: "Users Data",
      url: "/home/user",
      icon: "icon-people",
      routeName: "user",
      // children: [
      //   {
      //     name: "Students",
      //     url: "/home/user/student",
      //     icon: "icon-user",
      //   },
      //   {
      //     name: "Teachers",
      //     url: "/home/user/teacher",
      //     icon: "icon-user",
      //   },
      //   // {
      //   //   name: "Merchants",
      //   //   url: "/home/user/merchants",
      //   //   icon: "icon-user"
      //   // },

      //   {
      //     name: "Admin",
      //     url: "/home/user/admin",
      //     icon: "icon-user",
      //   },
      // ],
    },
    // {
    //   name: "Groups",
    //   url: "/home/groups",
    //   icon: "icon-grid",
    // },
    {
      name: "Policies",
      url: "/home/policies",
      icon: "icon-shield",
      routeName: "policies",
    },
    {
      name: "Roles",
      url: "/home/roles",
      icon: "icon-organization",
      routeName: "roles",
    },
  ],
};
